import './ConnectButton.css';
import React, {Component} from "react";
import tos from './tos';
import onboard from "./onboard";
import 'dotenv/config';


class ConnectButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet_connected:false,
      wallet: '',
      metamask_installed:false,
      max_mint_qty: 0,
    }
  }

  connectWallet = async () => {
    let ready;
    try {
      await onboard.walletSelect();
      ready = await onboard.walletCheck();
      if (ready){
        this.props.changeWalletConnected(true);
        this.setState({

        });
        let account = onboard.getState().address;
        this.props.changeWalletState(account);
        const max_mint_qty = await tos.methods.accessList(account).call();
        this.props.changeMaxMintQty(max_mint_qty);
        this.setState({
          wallet:account,
          wallet_connected:true,
          max_mint_qty:max_mint_qty
        });
      }

    } catch(e) {
      alert('error on wallet select');
      this.props.changeWalletState('Error Connecting Wallet');
      this.props.changeWalletConnected(false);
      this.setState({
        wallet_connected: false,
      });
    }
  }

  render(){
    if (!this.state.wallet_connected) {
      return(
        <button disabled className="connect-button not-connected soldout" onClick={() => this.connectWallet() }>
          SOLD OUT
        </button>
      );
    } else {
      return(
        <button className="connect-button" onClick={() => this.connectWallet() }>
          Wallet: {this.state.wallet}
        </button>
      );
    };
  }
};

export default ConnectButton;