import Web3 from 'web3';

let web3;

try {
  web3 = new Web3(window.ethereum);
} catch(e) {
  console.log(e);
  web3 = false;
}

export default web3;