import './MintContent.css';
import React, {Component} from "react";
import tos from './tos';
import web3 from "./web3";
import MintButton from "./MintButton";
import ConnectButton from "./ConnectButton";


if(window.ethereum) {
  window.ethereum.on('chainChanged', ()=>{
    window.location.reload();
  })

  window.ethereum.on('accountsChanged', ()=>{
    window.location.reload();
  })
}

class MintContent extends Component {
  constructor(props) {
    super(props);
    this.state= {
      mint_state:false,
      wallet:'',
      wallet_connected:false,
      metamask_installed:false,
      mint_qty:1,
      max_supply:0,
      minted_num:0,
      contract_address: tos.options.address,
      network_name: 'mainnet',
      eth_nft:0,
      max_mint_qty:0
    };
  }

  async componentDidMount() {
    const { ethereum } = window;
    if (!ethereum) {
      this.setState({
        metamask_installed:false,
      });
    } else {
      this.setState({
        metamask_installed:true,
      });
      await this.getContractInfo();
    }
  }

  contractEtherscan = () => {
    return ('https://' + 'etherscan.io/address/' + this.state.contract_address);
  }

  getContractInfo = async () => {
    if (!tos) { return false };
    const max_supply = await tos.methods.MAX_SUPPLY().call();
    const minted_num = await tos.methods.totalSupply().call();
    const eth_nft = web3.utils.fromWei(await tos.methods.PRICE().call(), 'ether');
    this.setState({
      max_supply: max_supply,
      minted_num:minted_num,
      eth_nft: eth_nft,
    });
    return true;
  }

  postMintUpdate = async () => {
    const minted_num = await tos.methods.totalSupply().call();
    this.setState({
      minted_num:minted_num,
    });
    return true;
  }

  changeWalletState = (wallet_address) => {
    this.setState({
      wallet:wallet_address
    });
  }

  changeWalletConnected = (wallet_connected) => {
    this.setState({
      wallet_connected:wallet_connected
    });
  }

  changeMaxMintQty = (max_mint_qty) => {
    this.setState({
      max_mint_qty:max_mint_qty
    });
  }

  render(){
    return(
      <section>
        <div className="mint-content-item">
          {this.state.minted_num}/{this.state.max_supply}
        </div>
        <div className="mint-content-item">
          {this.state.eth_nft} ETH
        </div>
        <div>
          <ConnectButton
            changeWalletState={this.changeWalletState}
            changeWalletConnected={this.changeWalletConnected}
            changeMaxMintQty={this.changeMaxMintQty}
          />
        </div>
        <div>
          <MintButton
            changeMaxMintQty={this.changeMaxMintQty}
            wallet_connected = {this.state.wallet_connected}
            wallet = {this.state.wallet}
            eth_nft = {this.state.eth_nft}
            max_mint_qty = {this.state.max_mint_qty}
            postMintUpdate = {this.postMintUpdate}
          />
        </div>

        <div id="contract">
          Contract:
          <a href= {this.contractEtherscan()} target="_blank" rel="noreferrer">
            {this.state.contract_address} ({this.state.network_name})
          </a>
        </div>
      </section>
    );
  }

};

export default MintContent;