import './App.css';
import MintContent from "./MintContent";

function App() {
  return (
    <div className="App">
      <MintContent />
    </div>
  );
}

export default App;
