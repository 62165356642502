import Onboard from 'bnc-onboard';
import Web3 from 'web3';
import 'dotenv/config';

let web3;

const onboard = Onboard({
  dappId: process.env.ONBOARD_API,       // [String] The API key created by step one above
  networkId: 1,  // [Integer] The Ethereum network ID your Dapp uses.
  darkMode:true,
  hideBranding: true,
  walletSelect: {
    wallets:[
      { walletName:'metamask', preferred:true },
      { walletName: 'walletConnect',
        rpc: {
          ['1']: "https://mainnet.infura.io/v3/8221871f44d04f2799fc87b6f8141dc9"
        },
        preferred:true },
    ]
  },
  subscriptions: {
    wallet: wallet => {
      web3 = new Web3(wallet.provider)
    },
  },

});

export default onboard;