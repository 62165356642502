import './MintButton.css';
import React, {Component} from "react";
import tos from "./tos";
import web3 from "./web3";


class MintButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet:this.props.wallet,
      wallet_connected:this.props.wallet_connected,
      mint_qty:this.props.max_mint_qty,
      eth_nft: this.props.eth_nft,
      mint_price_string:'0',
      mint_response:'',
      mint_error:false,
      max_mint_qty:this.props.max_mint_qty,
      mint_active: false,
      wl_active:false,
      max_qty:0,
    };
    this.gasLimit = {
      0: 90000,
      1: 130000,
      2: 180000,
      3: 250000,
      4: 300000,
      5: 300000,
    }
  };


  async componentDidMount(){
    const mint_active = await tos.methods.isMintActive().call();
    const wl_active = await tos.methods.isWhitelistActive().call();
    const max_qty = await tos.methods.MAX_QTY().call();

    this.setState({
      mint_active: mint_active,
      wl_active:wl_active,
      max_qty:max_qty,
    })

    if (!wl_active) {
      this.setState({
        max_mint_qty:max_qty,
        mint_qty:max_qty
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.eth_nft !== prevState.eth_nft) {
      this.setState({
        eth_nft: this.props.eth_nft,
      })
    }
    if (this.props.wallet !== prevState.wallet) {
      this.setState({
        wallet: this.props.wallet
      })
    }
    if (this.props.wallet_connected !== prevState.wallet_connected) {
      this.setState({
        wallet_connected: this.props.wallet_connected
      })
    }
    if (this.state.wl_active) {
      if ((this.props.max_mint_qty !== prevState.max_mint_qty)) {
        this.setState({
          max_mint_qty: this.props.max_mint_qty,
          mint_qty: this.props.max_mint_qty
        })
      }
    }

  }

  setMintState = () => {
    this.setState({
      minting:true
    });
    return true;
  }

  updateMintQty = (event) => {
    let mint_price = this.state.eth_nft * event.target.value;
    let mint_price_string = '' + mint_price;
    this.setState({
      mint_qty:event.target.value,
      mint_price_string: mint_price_string
    });
  }

  mintNFT = async () => {
    let mint_price_string = '' + Math.ceil(100*this.state.mint_qty * this.state.eth_nft)/100;
    this.setState({
      minting:false,
      mint_response:'',
      mint_price_string:mint_price_string
    });
    if (!tos) {
      this.setState({
        minting:false,
        mint_response:'contract cannot be loaded',
      });
      return false;
    }

    await this.setState({
      minting:true
    });

    let response;
    try {
      response = await tos.methods.mint(this.state.mint_qty).send({
        from:this.state.wallet,
        value: web3.utils.toWei(this.state.mint_price_string, 'ether'),
        gasLimit:this.gasLimit[this.state.mint_qty]
      });

    } catch (e) {
      this.setState({
        minting:false,
        mint_response:e.message,
      });
    }

    let response_txt;
    try{
      response_txt = 'Success:' + response.transactionHash;
      this.setState({
        minting:false,
        mint_response:response_txt,
      });
    } catch (e) {
      response_txt = 'Transaction unsuccessful';
      this.setState({
        minting:false,
        mint_response:response_txt,
      });
    }
    if (this.state.wl_active) {
      const max_mint_qty = await tos.methods.accessList(this.state.wallet).call();
      this.props.changeMaxMintQty(max_mint_qty);
      this.setState({
        max_mint_qty:max_mint_qty
      });
    }
    this.props.postMintUpdate();
  }

  mintButtonText = (minting) => {
    if (!this.state.wallet_connected) {
      return (
        <div>
        </div>
      );
    }

    if (!this.state.mint_active) {
      return (
        <div id="mint-area">
          <button id="inactive-mint-button" disabled>
            Mint InActive
          </button>
        </div>
      );
    }


    let mint_button;
    let mint_status_text;
    if (this.state.wl_active) {
      mint_status_text = "Whitelist Mint"
    } else {
      mint_status_text = "Public Mint"
    }
    if(minting) {
      mint_button =
        <div id="mint-area">
          <input id="mint-qty"  type="number" min="1" max={this.state.max_mint_qty} value={this.state.mint_qty}
                 onChange={this.updateMintQty} disabled />
          <button id="mint-button" disabled>
            Minting...
          </button>
          <div id="mint-status">
            {this.state.mint_response}
          </div>
        </div>;

    } else {
      mint_button =
        <div id="mint-area">
          <input id="mint-qty" type="number" min="1" max={this.state.max_mint_qty} value={this.state.mint_qty}
                 onChange={this.updateMintQty} /> {mint_status_text} (max qty: {this.state.max_mint_qty})
          <button id="mint-button"  onClick={ () => this.mintNFT() }>
            Mint
          </button>
          <div id="mint-status">
            {this.state.mint_response}
          </div>
        </div>;
    }
    return mint_button;
  }

  render(){

    return(
      this.mintButtonText(this.state.minting)
    );
  }
}

export default MintButton;